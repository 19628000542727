@import url("https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans JP", sans-serif,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer a {
  color: #fff;
}

/* スキル */
.skill {
  font-size: 11px;
}

.td-header {
  font-weight: bold;
  background-color: #eee !important;
}

.skill_normal {
  background-color: #fff !important;
}

.skill_highlight {
  background-color: #ffff8d !important;
}

@media print {
  .container {
    max-width: 98% !important;
  }

  .break_page {
    page-break-before: always;
  }

  footer,
  .navbar,
  .gone {
    display: none !important;
  }
}